<template>
  <div>
    <div v-if="!cycle_loading && !stock_loading">
      <div class="is-flex is-flex-wrap-wrap mb-3">
        <h2 class="is-size-5 has-text-weight-bold has-text-dark mr-6">Riwayat Laporan</h2>
        <b-datepicker
            :placeholder="$t('date')"
            icon="calendar-text"
            locale="id-ID"
            v-model="selectedDate"
            class="mr-2 mb-2">
        </b-datepicker>
        <sg-select :items="selectedFarm.blocks" type="is-secondary is-light"
                   :prepend="$t('farm.block')"
                   class="mr-2 mb-2 ml-0"
                   label="block_name"
                   v-model="selectedBlock"
                   @change="refreshData"
                   :placeholder="`${$t('select')} ${$t('farm.block')}`"/>
      </div>

      <sg-tab class="border-top-0 mb-4" v-model="selectedTab" :items="options" @input="handleSelectedTab"/>

      <div v-if="loaded">
        <div v-if="selectedTab === $t('wq.wq')">
          <water-quality-analysis/>
        </div>
        <div v-else-if="selectedTab === $t('warehouse.product')">
          <products-analysis/>
        </div>
        <div v-else-if="selectedTab === $t('warehouse.feed')">
          <products-analysis default-type="feed" hide-tab/>
        </div>
        <div v-else-if="selectedTab === $t('warehouse.probiotics')">
          <products-analysis default-type="probiotics" hide-tab hide-chart/>
        </div>
        <div v-else-if="selectedTab === $t('feed_tray.feed_tray')">
          <feed-tray-analysis/>
        </div>
        <div v-else-if="selectedTab === $t('water_change.water_change')">
          <water-change-overview/>
        </div>
        <div v-else-if="selectedTab === 'Sampling'">
          <sampling :selected-block="selectedBlock"/>
        </div>
        <div v-else-if="selectedTab === $t('death.death')">
          <death-count/>
        </div>
        <div v-else-if="selectedTab === $t('stock.stock')">
          <stock-cycle :selected-block="selectedBlock"
                       data-third-step="0"
                       ref="stockCycle"/>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import SgTab from "@/components/Sg/SgTab";
import {mapGetters, mapState} from "vuex";
import SgSelect from "@/components/Sg/SgSelect";

export default {
  name: "ReportHistory",
  components: {
    StockCycle: () => import("@/components/Farm/Overview/StockCycle"),
    DeathCount: () => import("@/components/Farm/Overview/DeathCount"),
    Sampling: () => import("@/components/Farm/Overview/Sampling"),
    WaterChangeOverview: () => import("@/components/Farm/Overview/Products/WaterChangeOverview"),
    ProductsAnalysis: () => import("@/components/Farm/Daily/ProductsAnalysis"),
    FeedTrayAnalysis: () => import("@/components/Farm/Daily/FeedTrayAnalysis"),
    WaterQualityAnalysis: () => import("@/components/Farm/Daily/WaterQualityAnalysis"),
    SgSelect,
    SgTab
  },
  data() {
    return {
      selectedTab: this.$t('wq.wq'),
      selectedBlock: null,

      cycle_loading: false,
      stock_loading: false,

      loaded: false,
    }
  },
  computed: {
    ...mapGetters('farm', [
      'isPro',
    ]),

    options () {
      let res = [
        this.$t('wq.wq'),
        this.$t('feed_tray.feed_tray'),
        this.$t('warehouse.product'),
        this.$t('warehouse.feed'),
        this.$t('warehouse.probiotics'),
        this.$t('water_change.water_change'),
        'Sampling',
        this.$t('death.death'),
        this.$t('stock.stock'),
      ]

      if (!this.isPro) {
        delete res[5]
        delete res[2]
        delete res[1]
      } else {
        delete res[8]
        delete res[7]
        delete res[6]
        delete res[4]
        delete res[3]
      }

      return res.filter(e=>e)
    },

    canViewWqs() {
      return this.$store.getters["farm/canViewWqs"]
    },
    farm_id() {
      return parseInt(this.$route.params.farm_id)
    },
    selectedDate: {
      get() {
        return this.$store.state.farm.analysis.date
      },
      set(value) {
        this.$store.dispatch('farm/updateSelectedFarmStatusDate', value)
        this.refreshData()
      },
    },
    selectedFarm() {
      return this.$store.state.farm.selectedFarm
    },
    selectedPonds: {
      get() {
        return this.$store.state.farm.overview.ponds
      },
      set() {
      }
    },

    ...mapState('farm', [
      'analysis'
    ]),
  },
  beforeDestroy() {
    this.$store.dispatch('farm/updateSelectedFarmStatusDate', this.selectedDate)
  },
  created() {
    this.cycle_loading = true
    this.stock_loading = true
    this.$loading()

    Promise.all(
        [
          ...this.selectedFarm.ponds.map(e => {
            return this.$store.dispatch('farm/getCycleOverview', {pond: e})
          }),
          ...this.selectedFarm.ponds.map(e => {
            return this.$store.dispatch('farm/getStockOverview', e)
          })
        ]
    ).then(res => {
      if (this.selectedFarm.blocks && this.selectedFarm.blocks.length) {
        this.selectedBlock = this.selectedFarm.blocks[0]
      }

      this.refreshData()
    }).finally(() => {
      this.cycle_loading = false
      this.stock_loading = false
      this.$loading(false)
    })
  },
  methods: {
    handleSelectedTab () {
      this.loaded = false
      this.$nextTick(() => {
        this.loaded = true
      })
    },

    refreshData() {
      this.loaded = false

      this.analysis.date = new Date(this.$startDay(this.analysis.date))

      let date = this.$timestamp(this.analysis.date)

      let ponds = []

      if (this.selectedBlock) {
        ponds = this._.cloneDeep(this.selectedFarm.ponds.filter(e => e.block_id === this.selectedBlock.block_id)).map(e => {
          e.selected_cycle = e.cycles ? e.cycles.find(f => {
            return date >= f.start_date && (f.end_date === 0 || date <= f.end_date)
          }) : null
          return e
        })
      }

      this.$store.commit('farm/UPDATE_OVERVIEW_PONDS', ponds)

      this.$nextTick(() => {
        this.$store.dispatch('farm/refreshOverview')
        this.loaded = true
      })
    },
  }
}
</script>
